var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('vue-agile',{staticClass:"index-slider",attrs:{"options":_vm.agileOptions}},[_c('div',{staticClass:"index-slide index-slide--event"},[_c('img',{staticClass:"_art-1",attrs:{"src":require('@/assets/img/index/' + _vm.season + '-event-art-1.png')}}),_c('img',{staticClass:"_art-2",attrs:{"src":require('@/assets/img/index/' + _vm.season + '-event-art-2.png'),"alt":""}}),_c('div',{staticClass:"_bg",style:({
          backgroundImage:
            'url(' +
            require('@/assets/img/index/' + _vm.season + '-event-bg.png') +
            ')',
        })},[_c('div',{staticClass:"_super-title"},[_vm._v("Приключение")])]),_c('div',{staticClass:"_bg--mob",style:({
          backgroundImage:
            'url(' +
            require('@/assets/img/index/' + _vm.season + '-event--mob.png') +
            ')',
        })}),_c('div',{staticClass:"_box"},[_c('div',{staticClass:"_text"},[_c('router-link',{attrs:{"to":"/event"}},[_c('h2',{staticStyle:{"display":"inline-flex"}},[_vm._v(" Забирай Gingerbread Baby Roshan ")])]),_c('p',{staticStyle:{"overflow":"hidden","width":"1px","height":"1px"}},[_vm._v(" Учавствуй в приключении и выводи бесплатные предметы дота 2 ")])],1),_c('router-link',{staticClass:"_btn-wrap",attrs:{"to":"/event"}},[_c('Button',{staticClass:"_desk",attrs:{"type":'show-more-simple',"text":'Узнать подробнее'}}),_c('Button',{staticClass:"_mob",attrs:{"type":'show-more-simple',"text":'Подробнее'}})],1)],1)]),_c('div',{staticClass:"index-slide index-slide--runes"},[_c('img',{staticClass:"_art-1",attrs:{"src":require('@/assets/img/index/banner-runes-art-left.png')}}),_c('img',{staticClass:"_art-2",attrs:{"src":require('@/assets/img/index/banner-runes-art-right.png')}}),_c('div',{staticClass:"_bg",style:({
          backgroundImage:
            'url(' + require('@/assets/img/index/banner-runes-bg.png') + ')',
        })},[_c('div',{staticClass:"_super-title"},[_vm._v("РУНОМАНИЯ")])]),_c('div',{staticClass:"_bg--mob",style:({
          backgroundImage:
            'url(' + require('@/assets/img/index/banner-runes-mob.png') + ')',
        })}),_c('div',{staticClass:"_box"},[_c('div',{staticClass:"_text"},[_c('h2',{staticStyle:{"display":"inline-flex"}},[_vm._v("Дарим Арканы Каждый День")]),_c('p',{staticStyle:{"overflow":"hidden","width":"1px","height":"1px"}},[_vm._v(" Учавствуй в приключении и выводи бесплатные предметы дота 2 ")])]),_c('router-link',{staticClass:"_btn-wrap",attrs:{"to":"/event#farm"}},[_c('Button',{staticClass:"_desk",attrs:{"type":'show-more-simple',"text":'Узнать подробнее'}}),_c('Button',{staticClass:"_mob",attrs:{"type":'show-more-simple',"text":'Подробнее'}})],1)],1)]),_c('div',{staticClass:"index-slide index-slide--contracts"},[_c('img',{staticClass:"_art-1",attrs:{"src":require('@/assets/img/index/banner-contracts-art-left.png')}}),_c('img',{staticClass:"_art-2",attrs:{"src":require('@/assets/img/index/banner-contracts-art-right.png')}}),_c('div',{staticClass:"_bg",style:({
          backgroundImage:
            'url(' +
            require('@/assets/img/index/banner-contracts-bg.png') +
            ')',
        })},[_c('div',{staticClass:"_super-title"},[_vm._v("ПОТАЙНАЯ")])]),_c('div',{staticClass:"_bg--mob",style:({
          backgroundImage:
            'url(' +
            require('@/assets/img/index/banner-contracts-mob.png') +
            ')',
        })}),_c('div',{staticClass:"_box"},[_c('div',{staticClass:"_text"},[_c('h2',{staticStyle:{"display":"inline-flex"}},[_vm._v("Делай Это Выгодно")]),_c('p',{staticStyle:{"overflow":"hidden","width":"1px","height":"1px"}},[_vm._v(" Учавствуй в приключении и выводи бесплатные предметы дота 2 ")])]),_c('router-link',{staticClass:"_btn-wrap",attrs:{"to":"/promotions"}},[_c('Button',{staticClass:"_desk",attrs:{"type":'show-more-simple',"text":'Узнать подробнее'}}),_c('Button',{staticClass:"_mob",attrs:{"type":'show-more-simple',"text":'Подробнее'}})],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }