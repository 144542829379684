
import { Component, Vue } from 'vue-property-decorator';

import CasesPreview from '@/components/Index/CasesPreview.vue';
import PromoBanner from '../components/PromoBanner.vue';
import Button from '../components/Buttons/Button.vue';
import Banners from '@/components/Index/Banners.vue';
import LoadingScreen from '@/components/Layout/LoadingScreen.vue';

@Component({
  components: {
    LoadingScreen,
    Banners,
    CasesPreview,
    PromoBanner,
    Button,
  },
})
export default class Index extends Vue {
  created() {
    this.$setPageTitle('DOTALOOT - Эпические кейсы из Dota 2, Дота 2');
  }

  async mounted() {
    await this.$store.dispatch('caseCategories/fetch');

    if (window.location.hash) {
      const divName = window.location.hash.replace('#', '');
      setTimeout(function () {
        const cases = document.getElementById(divName);
        cases.scrollIntoView({behavior: 'smooth'});
        setTimeout(() => {
          window.scrollBy(0, -40);
        }, 650);
      }, 500);
    }
  }

  get categories() {
    return this.$store.state.caseCategories.categories;
  }

  get isLoading() {
    return this.$store.state.caseCategories.isLoading;
  }

  get user() {
    return this.$store.getters['user/isAuthorized']
        ? this.$store.state.user
        : null;
  }
}
